.contact-page header {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/images/Contact.jpg);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contact-page .header-description {
  font-size: 1.2rem;
}

#contact-form {
  width: 50%;
}

.contact-page .header-content {
  max-width: 800px;
  margin: 0 auto;
}

#contact-form .form-control:focus,
#contact-form .form-select:focus {
  border-color: #FF7518;
  box-shadow: 0 0 0 0.25rem rgba(255, 146, 3, 0.199);
}

@media only screen and (max-width: 992px) {
  #contact-form {
    width: 75%;
  }
}

@media only screen and (max-width: 576px) {
  #contact-form {
    width: 100%;
  }
}
