/* Courses.css */
.teachers-page header {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/images/Teachers.jpg);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.teacher-card {
  flex: 1 0 45%; /* Adjust the width as needed */
  margin: 0 1rem; /* Adjust the margin between cards */
  max-width: 400px; /* Limit the maximum width of each card */
}

/* Teachers.css */

.teacher-card-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  justify-content: center;
}

.teacher-card {
  flex: 1 0 calc(50% - 1rem); /* Adjust the width as needed */
  margin-bottom: 1rem; /* Adjust the margin between rows */
  max-width: calc(50% - 1rem); /* Limit the maximum width of each card */
}

@media (max-width: 950px) {
  .teacher-card {
    flex: 1 0 100%; /* Set each card to take up 100% of the width */
    max-width: 100%;
    margin: 1rem 0; /* Set maximum width to 100% */
    
  }

}


.teachers-page .header {
  background-color: #343a40;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

.teachers-page .header-content {
  max-width: 800px;
  margin: 0 auto;
}

.teachers-page .header-title {
  font-size: 2.5rem;
}

.teachers-page .header-description {
  font-size: 1.2rem;
}

.teachers-page .custom-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.teachers-page .custom-card-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.teachers-page .custom-card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  transition: transform 0.3s ease;
  transform: translateY(100%);
}

.teachers-page .custom-card:hover .custom-card-overlay {
  transform: translateY(0);
}

.teachers-page .custom-card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.teachers-page .custom-card-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.teachers-page .custom-card-button {
  background-color: #FF7518;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teachers-page .custom-card-button:hover {
  background-color: #FF7518;
}
