/* Courses.css */
.boards-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/Boards.jpg);
  }
  
  
  .boards-page .header {
    background-color: #343a40;
    color: #fff;
    padding: 2rem 0;
    text-align: center;
  }
  
  .boards-page .header-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .boards-page .header-title {
    font-size: 2.5rem;
  }
  
  .boards-page .header-description {
    font-size: 1.2rem;
  }
  
  .boards-page .custom-card {
    position: relative;
    
    overflow: hidden;
    /* border-radius: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .boards-page .custom-card-img {
    width: 100%;
    height: auto;
    /* border-radius: 10px; */
  }
  
  .boards-page .custom-card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    transition: transform 0.3s ease;
    transform: translateY(100%);
  }
  
  .boards-page .custom-card:hover .custom-card-overlay {
    transform: translateY(0);
  }
  
  .boards-page .custom-card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .boards-page .custom-card-description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .boards-page .custom-card-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    /* border-radius: 5px; */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .boards-page .custom-card-button:hover {
    background-color: #c82333;
  }
  

/* Boards.css */

/* Add media query for large screens */
@media (min-width: 768px) {
  .boards-page .flex {
    display: flex;
    
    flex-wrap: wrap; /* Allow cards to wrap onto new line if necessary */
    justify-content: center; /* Align cards with space between them */
  
  }
  
  .boards-page .custom-card {
    width: calc(50% - 20px); /* Calculate width for two cards with margin */
    margin-bottom: 20px; /* Add margin between rows */
  }
}

/* Adjustments for small screens */
@media (max-width: 767px) {
  .boards-page .flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; /* Allow cards to wrap onto new line if necessary */
    justify-content: center;
    align-items: center; /* Align cards center */
  }
  
  .boards-page .custom-card {
    width: 90%; /* Make cards take up full width of container */
    margin-bottom: 20px; /* Add margin between rows */
    padding: 0 30%;
  }
}
