/* grades.css */

.grades-page header {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/images/Grades.jpg);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.grades-page .header-content {
  max-width: 800px;
  margin: 0 auto;
}

.grades-page .header-title {
  font-size: 2.5rem;
}

.grades-page .header-description {
  font-size: 1.2rem;
}

.grades-page .card-img-overlay {
  background-color: rgba(5, 10, 35, 0.7);
}

.grades-page .card:hover {
  cursor: pointer;
}

/* Set fixed height for GradeCard */
.grade-card {
  height: 300px; /* Adjust height as needed */
}

/* Adjust layout for smaller screens */
@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
