.home-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/home-page-header.jpg);
}

header {
    background-size: cover;
    background-position: center;
}

.home-page .header-description {
    font-size: 1.2rem;
    padding:  0 10%; 
  }

  .home-page .header-title {
    font-size: 2.5rem;
  }

