@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


code {
  font-family: "Montserrat", sans-serif;
}


