/* Courses.css */
.courses-page header {
  background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/contact-page-header.jpg);
}


.courses-page .header {
  background-color: #343a40;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

.courses-page .header-content {
  max-width: 800px;
  margin: 0 auto;
}

.courses-page .header-title {
  font-size: 2.5rem;
}

.courses-page .header-description {
  font-size: 1.2rem;
}

.courses-page .custom-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.courses-page .custom-card-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.courses-page .custom-card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  transition: transform 0.3s ease;
  transform: translateY(100%);
}

.courses-page .custom-card:hover .custom-card-overlay {
  transform: translateY(0);
}

.courses-page .custom-card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.courses-page .custom-card-description {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.courses-page .custom-card-button {
  background-color: #FF7518;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.courses-page .custom-card-button:hover {
  background-color: #ca6826;
}
