* {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.faq-section p,
.accordion {
  width: 75%;
}

.accordion .accordion-button:focus,
.accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) {
  background-color: #FF7518;
  color: #ffffff;
}

.accordion .accordion-button:not(.collapsed)::after {
  filter: invert();
}

@media only screen and (max-width: 992px) {
  .faq-section p,
  .accordion {
    width: 100%;
  }
}
